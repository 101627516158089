<template>
  <div class="barging-container h-full overflow-hidden" v-loading="loading">
    <div class="barging-card" style="padding:0px 0px; background-color:#f2f7fa; border-radius: 10px;">
      <!--div class="flex justify-end w-full items-center p-1 laptop:pb-2 desktop:pb-8">
        <el-input :placeholder="$t('btn.search')" style="width: 275px" size="mini"
          @keyup.enter.native="getDetail" v-model="searchValue">
          <span slot="suffix" @click="getDetail"><svg-icon iconClass="search" /></span>
        </el-input>
      </div-->
      <div class="box-card barging-table" v-bind:class="{'mobile-view':isMobileScreen}">

        <div class="w-full Comtable">
          <el-table
            :header-cell-style="{height: '55px',background: '#fff','border-bottom': '2px solid #D6D9E1'}"
            :data="tableData"
            style="width: 100%"
            :cell-style="getCellStyle"
            table-layout="auto"
            height="100%"
          >
            <el-table-column width="50" type="index" :label="$t('table.no')" align="left" />
            <el-table-column width="200" prop="equipmentName" :label="$t('table.equipment')" align="left" />
            <!-- <el-table-column width="100" prop="remarks" :label="$t('table.remarks')" align="left" /> -->
            <el-table-column width="200" prop="reportIssue" :label="$t('table.reportIssue')" align="left" />
            <el-table-column width="200" prop="reportedTime" :label="$t('table.reportedOn')" align="left" />
            <el-table-column width="150" prop="reportedByName" :label="$t('table.reportedBy')" align="left" />
            <el-table-column width="150" :label="$t('common.status')" align="left">
              <tempate slot-scope="scope">
                {{  scope.row.status == 1 ? 'Resolved' : 'Pending' }}
              </tempate>
            </el-table-column>
          </el-table>
        </div>
      </div>
     

      <div class="barging-pagination flex justify-end">
          <pagination
            v-show="total > 0"
            style="background: #f2f7fa"
            :total="total"
            :page.sync="params.pageNum"
            :limit.sync="params.pageSize"
            @pagination="handlePagechange"
          ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getEquipmentReports } from "@/services/form"
import { mapState } from "vuex";
export default {
  name: "EquipmentReport",
  data() {
    return {
      tableData: [],
      getList: [],
      loading: false,
      searchValue: '',
      total: 0,
      params: {
        pageNum: 1,
        pageSize: 10,
      }
    }
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  async created() {
    await this.getDetail()
  },
  methods: {
    async getDetail() {
      this.loading = true
      await getEquipmentReports( this.params ).then(res => {
        this.tableData = res.data.table.rows
        this.total = res.data.table.total
      }).finally(() => {
        this.loading = false
      })
    },
    async handlePagechange(object) {
      this.params.pageNum = object.page
      this.params.pageSize = object.limit
      await this.getDetail()
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
  font-weight: bold;
}
::v-deep .el-dialog__title {
  color: #82889c;
  font-size: 16px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
::v-deep .el-table th.el-table__cell {
  font-size: 12px;
  font-weight: bold;
}
::v-deep .el-table td.el-table__cell div {
  font-weight: 400;
  font-size: 11px;

  color: #2e3139;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}

.barging-card {
  position: absolute;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  height: calc(100vh - 90px);
}
@media (max-width: 1022px) {
  .barging-card {
    // height: calc(100vh - 90px);
  }
}
@media (max-width: 1023px) {
  .Comtable {
    height: calc(100vh - 125px);
  }
  .mobile-view{
    .Comtable {
      height: calc(100vh - 115px);
    }
  }
}
@media (min-width: 1024px) {
  .Comtable {
    height: calc(100vh - 125px);
  }
}
</style>
